
import "swiper/css/swiper.css";
import './App.scss';
import Router from './Router';

declare var _hmt:any[];

window.addEventListener("hashchange",function(){
    if(typeof _hmt!=="undefined"){
        _hmt.push(["_trackPageview",window.location.href])
    }
},false);

function App() {

  return (   
      <Router />
  );
}

export default App;
