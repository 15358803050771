import { useEffect, useRef, useState } from 'react';
import device from './device';


function useWinSizeChange(){
    var state=useRef(useState(device.size));    
   
    
    useEffect(function(){
        var sizeChange = function (this: typeof window, e: any) {
            state.current[1](e.detail.size);
        };

        window.addEventListener("sizechange",sizeChange,false);

        return function(){
            window.removeEventListener("sizechange",sizeChange,false);
        };
    },[]);

    return state.current;    
}

export default useWinSizeChange;