import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { background } from "../common/utils";

function NavOfPC(props:{
    logo:string,
    navList:{
        name:string,
        to:string
    }[]
}) {

    var {logo,navList}=props;
    
    var onNavClick = useCallback(function () {
        window.scrollTo(0, 0);
    }, []);

    return (
        <nav className="nav fs is-pc">
            <div className="nav-inner">
                <div className="df mw">
                    <a href={$PUBLIC_PATH ? $PUBLIC_PATH : "/"} className="logo" style={background(logo)}>
                    </a>
                    <ul className="df f1 nav-list">
                        {


                            navList.map(function (item, index) {
                                return (
                                    <li key={index}><NavLink exact to={item.to} onClick={onNavClick}>{item.name}</NavLink></li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavOfPC;