
import { preventDefault } from "../common/utils";
import "./FooterView.scss";
import OuterLink from "./OuterLink";

function FooterView(){

    return (
        <footer className="m-b fs tr footer-view">
            <div className="inner">                
                <span className="m">Follow us on</span>
                <OuterLink href="https://www.facebook.com/PepsiCoJOBS/" className="m"></OuterLink>
                <OuterLink href="https://twitter.com/PepsiCoJOBS" className="m"></OuterLink>
                <OuterLink href="https://www.linkedin.com/company/pepsico/" className="m"></OuterLink>
                <a href="#!" onClick={preventDefault} className="m weixin">
                    <div>
                       <img src={require("../assets/images/footer/qrcode.png").default} alt="" />
                   </div>
                </a>
                <OuterLink href="https://www.instagram.com/pepsicojobs/" className="m"></OuterLink>
                <OuterLink href="http://nav.cx/1nvn5GA" className="m"></OuterLink>
            </div>            
        </footer>
    )
}

export default FooterView;