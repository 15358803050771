import ReactDOM from 'react-dom';
import App from './App';
import device from './common/device';
import { throtte } from './common/utils';
import reportWebVitals from './reportWebVitals';


var deviceWidths = [900, 1160];
var w = window.innerWidth;
var getSize = function (width: number): "min" | "middle" | "max" {
  return width < deviceWidths[0] ? "min" : width < deviceWidths[1] ? "middle" : "max"
};

device.size = getSize(w);
window.addEventListener("resize", throtte(function () {
  var w = window.innerWidth;
  var size = getSize(w);
  var event;

  if (size !== device.size) {
    event = new CustomEvent("sizechange", { detail: { size, oldSize: device.size } });
    device.size = size;
    window.dispatchEvent(event);
  }
}, 200), false);

document.addEventListener("touchstart",function(){},false);


ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
