
function OuterLink(props:{
    href:string,
    self?:boolean,
    className?:string,
    children?:any,
    download?:string
}){
    return (
        props.download?
        <a className={props.className} href={props.href} download={props.download} target={props.self?"_self":"_blank"} rel="noreferrer">
            {
                props.children
            }
        </a>:
        <a className={props.className} href={props.href} target={props.self?"_self":"_blank"} rel="noreferrer">
            {
                props.children
            }
        </a>
    )
}

export default OuterLink;