import { useCallback, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { background } from "../common/utils";
import "./NavOfMobile.scss";

/**
 * 移动端导航
 * @param props 
 */
function NavOfMobile(props:{
    logo:string,
    navList:{
        name:string,
        to:string
    }[]
}){

    var [show,setShow]=useState(false);

    var menuButtonClick=useCallback(function(e:any){
           e.preventDefault();
           setShow(!show);
    },[show]);

    return (
        <nav className="m-s nav fs is-mobile">
            <div className="nav-inner">
               <div className="df mw">
                    <a href={$PUBLIC_PATH ? $PUBLIC_PATH : "/"} className="logo" style={background(props.logo)}>
                    </a>

                    <div className="f1 apply-wrap">
                       <Link to="/apply">APPLY NOW</Link>
                    </div>
                    
                    <a className={"menu-btn"+(show?" on":"")} href="#!" onClick={menuButtonClick}> </a>
               </div>
            </div>

            <div className={"df menu-layer"+(show?" on":"")} onClick={menuButtonClick}>
                <ul className="tc f1">
                    {
                        props.navList.map(function(item,index){
                            return (
                                <li key={index}><NavLink exact to={item.to}>{item.name}</NavLink></li>
                            )
                        })
                    }
                </ul>
            </div>
        </nav>
    )
}

export default NavOfMobile;