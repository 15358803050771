import { HashRouter, Route, Switch } from "react-router-dom";
// import Loadable from "react-loadable";
import Loadable from "@loadable/component";
import FooterView from "./components/FooterView";
import NavView from "./components/NavView";

// var loading = function () {
//   return (
//     <div className="empty-loading page-loading"></div>
//   );
// };
var Home = Loadable(() => import("./views/Home"));

var Program = Loadable(() => import("./views/Program"));
var About = Loadable(() => import("./views/About"));
var Career = Loadable(() => import("./views/Career"));
var Apply = Loadable(() => import("./views/Apply"));
var Team = Loadable(() => import("./views/Team"));
var Qa = Loadable(() => import("./views/Qa"));


export default function Router() {
  return (
    <HashRouter>
      <Switch>
         <Route exact path="/" component={Home}></Route>
         <Route>
            <NavView /> 
               <Switch>
                  <Route exact path="/Program" component={Program}></Route>  
                  <Route exact path="/about" component={About}></Route>  
                  <Route exact path="/careers" component={Career}></Route>  
                  <Route exact path="/apply" component={Apply}></Route>  
                  <Route exact path="/team" component={Team}></Route>  
                  <Route exact path="/qa" component={Qa}></Route>  
                </Switch>    
            <FooterView />
         </Route>
      </Switch>      
    </HashRouter>
  )
}