

function noop() { }

function request(options: { url: string, method?: string, data?: any, dataType?: string, success?: (data: any) => void, fail?: (data: any) => void }): XMLHttpRequest {
    var xhr = new XMLHttpRequest();
    var data = options.data || null;
    var method = options.method || "GET";
    var url = options.url;
    var paramArr;
    if (method === "GET" && data && typeof data === "object") {
        paramArr = [];
        if (url.lastIndexOf("?") === -1) {
            url += "?";
        }
        else {
            url += "&";
        }
        for (var k in data) {
            paramArr.push(k + "=" + encodeURIComponent(data[k]));
        }

        url += paramArr.join("&");
        data = null;
    }

    xhr.open(method, url, true);
    xhr.onload = function () {
        var text = this.responseText;
        var dataType = options.dataType || "json";
        var success = options.success || noop;
        dataType === "json" ? success(JSON.parse(text)) : success(text);
    };
    xhr.onerror = function (err) {
        var fail = options.fail || noop;
        fail(err);
    };

    xhr.send(data && (typeof data === "object") ? JSON.stringify(data) : data);

    return xhr;
}

function openFullscreen(element: any) {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
    }
}


//退出全屏方法
function exitFullscreen() {
    var doc: any = document;
    if (!doc.fullscreenElement) {
        return;
    }
    if (doc.exitFullscreen) {
        doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
    } else if (doc.msExitFullscreen) {
        doc.msExiFullscreen();
    } else if (doc.webkitCancelFullScreen) {
        doc.webkitCancelFullScreen();
    } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
    }
}

function throtte(f: (evt?: Event) => void, timeout = 100) {
    var timer = 0;
    var start = 0;

    return function (this: any, evt: Event) {
        clearTimeout(timer);
        if (evt.timeStamp - start > timeout) {
            start = evt.timeStamp;
            f();
        }
        timer = setTimeout(f, timeout);
    };
}

function preventDefault(e:any){
    e.preventDefault();
}

function getJson(src:string,cb?:(value:unknown)=>void){
  var d=document,
      s=d.createElement("script");
      s.async=true;
      s.onload=function(){
         cb&&cb(_json);
         _json=null;
         d.head.removeChild(s);
      };
      s.src=src;
      d.head.appendChild(s);
}

function getJsonAsync(src:string){
   return new Promise(function(resolve){
      getJson(src,resolve);
   });
}

function background(url: any) {
    return {
        backgroundImage: "url('" + (typeof url === "object" ? url.default : url) + "')"
    };
}

var _json:any;

Object.defineProperty(window,"$use",{
    writable:false,
    value(json:any){
       _json=json;
    }
});


export {
    noop,
    request,
    openFullscreen,
    exitFullscreen,
    throtte,
    preventDefault,
    getJson,
    getJsonAsync,
    background
};