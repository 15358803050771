
import "./NavView.scss";

import NavOfPC from "./NavOfPC";
import useWinSizeChange from "../common/useWinSizeChange";
import NavOfMobile from "./NavOfMobile";


export default function NavView() {

    var logo = require("../assets/images/logo.png");

    var navList = [
        {
            to: "/program",
            name: "Program Introduction"
        },
        {
            to: "/about",
            name: "About PepsiCo"
        },
        {
            to: "/careers",
            name: "Careers at PepsiCo"
        },
        {
            to: "/apply",
            name: "How to Apply"
        },
        {
            to: "/team",
            name: "Team and Culture"
        },
        {
            to: "/qa",
            name: "Q&A"
        },
    ];

    var [winSize]=useWinSizeChange();

    return (
        winSize === "min" ?
            <NavOfMobile logo={logo} navList={navList} /> :
            <NavOfPC logo={logo} navList={navList} />
    )
};